<template>
  <section>
    <div>
      Ooops, You found a blank page. Please contact us for information on locations
    </div>
  </section>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
